import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import OtherRouter from "./OtherRouter";

const HomePage = lazy(() => import("@/pages/HomePage"));
const TestPage = lazy(() => import("@/pages/TestPage"));

export default function MainRouter() {
  return (
    <Suspense fallback={<div>loading...</div>}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/test" element={<TestPage />} />
        <Route path="/other" element={<OtherRouter />} />
      </Routes>
    </Suspense>
  );
}
