import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
const OtherPage = lazy(() => import("@/pages/OtherPage"));

export default function OtherRouter() {
  return (
    <Suspense fallback={<div>loading...</div>}>
      <Routes>
        <Route path="/" element={<OtherPage />} />
      </Routes>
    </Suspense>
  );
}
